.communitySlideshow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  width: 100vw;
}

.communitySlideshow::-webkit-scrollbar {
  display: none;
}

.communitySlideshow {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.communityItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 4vw;
}

.communityItem h3 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: .5rem;
}

.creator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.featureImage {
  width: 40vw;
  max-width: 400px;
  border-radius: 30px;
  border: var(--border);
  margin-bottom: 1rem;
}

.creator img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: .5rem;
}

.creator a {
  font-family: "headlines";
  font-size: 18px;
}

@media (max-width:768px) {
  .communityItem {}
}