.mintModal {
  position: fixed;
  width: 500px;
  height: 600px;
  border-radius: 50px;
  background-color: white;
  z-index: 10;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border: var(--border);
}

.mintModalBG {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: var(--dedFamBlack);
  opacity: .9;
  z-index: 9;
}

.modalContent {
  width: 100%;
  padding: 2rem;
}

.modalContent h3 {
  font-size: 64px;
  margin-bottom: 2rem;
}

.modalContent p {
  font-size: 30px;
  width: 100%;
  font-family: "text";
}

.closeContainer {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  margin: -10px 0 0 -10px;
}

.close {
  position: relative;
  width: 40px;
  height: 3px;
  background-color: var(--dedFamBlack);
  transform: rotate(45deg);
  top: 50%;
}

.close::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 40px;
  background-color: var(--dedFamBlack);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}