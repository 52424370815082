.faqContainer {
  background-image: url("../../assets/backgrounds/Sky.png");
  background-color: var(--dedfamBlue);
}

.faq {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.faqLine {
  cursor: pointer;
  border-top: var(--border);
  border-bottom: var(--border);
  margin-bottom: -3px;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: calc(-1*var(--borderWidth));
}

.faqLine h3 {
  font-size: 40px;
  font-family: "text";
}

.faqContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plusContainer {
  width: 40px;
  height: 40px;
}

.plus {
  position: relative;
  width: 40px;
  height: 3px;
  background-color: var(--dedFamBlack);
  margin-right: 2rem;
  transform: rotate(0);
  transition: all .6s ease-in-out;
}

.plus::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 40px;
  background-color: var(--dedFamBlack);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.active .plus {
  transform: rotate(45deg);
}

.faqAnswer {
  max-height: 0;
  transition: all .6s;
  overflow: hidden;
  font-size: 20px;
  transition: all .6s ease-in-out;
}

.active .faqAnswer {
  max-height: 30rem;
}

.faqAnswer p {
  font-family: "text";
  padding: 4rem 0 2rem;
  line-height: 1.25;
  font-size: 26px;
}