.exploreSection {
  background-color: var(--dedFamBlack);
  background-image: url("../../assets/backgrounds/Xerox.png");
  height: 894px;
  transform: translateZ(0)
}

@media (max-width:768px) {
  .exploreSection {
    max-height: unset;
  }
}