.nft {
  width: 100vw;
  border-top: var(--border);
  border-bottom: var(--border);
}

.traitTitle {
  font-size: 40px;
  grid-column: 0;
  grid-row: 1;
  width: 100%;
  border-bottom: var(--border);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.traitTitle ul {
  display: flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.traitTitle h3 {
  font-family: "headlines";
  margin: .5rem 3rem .5rem .5rem;
}

.traitTitle p {
  font-family: "text";
  font-size: 22px;
  margin-right: 1.5rem;
}

.traitGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: var(--dedFamBlack);
  grid-gap: var(--borderWidth);
}

.traitContainer {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.traitText {
  position: absolute;
  left: .5rem;
  top: .5rem;
  max-width: 100%;
  z-index: 4;
}

.traitText h5 {
  margin-bottom: .25rem;
  text-transform: uppercase;
}

.traitText p {
  font-family: "text";
  font-size: 16px;
}

.trait {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
}

.traitContainer img {
  transition: transform .2s;
}

.traitContainer:hover img {
  transform: scale(1.2);
}

.traits {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

.traits h3 {
  font-family: "headlines";
  font-size: 40px;
  margin-bottom: 2rem;
}

.traits ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.traits li {
  font-family: "text";
  font-size: 24px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.explainerLine {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;

}

@media (max-width:768px) {
  .traitTitle {
    flex-direction: column;
  }

  .traitTitle h3 {
    margin-bottom: 1rem;
  }

  .traitGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}