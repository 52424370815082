.socialsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dedFamOrange);
  border-top: var(--border);
  border-bottom: var(--border);
}

.social:first-child {
  border-right: var(--border);
}

.social:last-child {
  border-left: var(--border);
}

.social img {
  transition: transform .2s;
}

.social:hover img {
  transform: scale(1.5);
}

.social {
  position: relative;
  aspect-ratio: 1;
  width: 33.33333%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}

.social h3 {
  font-family: "headlines";
  font-size: 40px;
  letter-spacing: -2px;
  color: white;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.socialImageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.socialImages {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.socialOverlay {
  width: 100%;
  height: 100%;
  background-color: var(--overlay);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

@media (max-width:768px) {
  .social h3 {
    font-size: 20px;
  }
}