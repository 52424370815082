.roadmapContainer {
  /* background-color: var(--dedFamBlack); */
  background-image: url("../../assets/backgrounds/Aura.png");
  border-top: var(--border);
  width: 100vw;
}

.timeline {
  position: absolute;
  left: 10%;
  transform: translate(-50%, 0);
  top: calc((100%/8)*0.41);
  width: var(--borderWidth);
  height: 89%;
  background-color: var(--dedFamBlack);
}

.roadmap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.roadmapSection {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.roadmapDot {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  max-width: 42px;
  max-height: 42px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  background-color: var(--dedFamBlack);
  border: 5px solid var(--dedFamYellow);
  z-index: 1;
}

.roadmapPath img {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border: 5px solid var(--dedFamBlack);
  border-radius: 50%;
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
  z-index: 2;
}

.roadmapPath {
  position: relative;
  width: 20%;
  /* height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.roadmapPath::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 4px;
  height: 40%;
  background-color: var(--dedFamBlack);
  z-index: 0;
}

.roadmapSection:first-of-type .roadmapPath::before,
.roadmapSection:last-of-type .roadmapPath::after {
  width: 0;
  height: 0;
}

.roadmapPath::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0);
  width: 4px;
  height: 60%;
  background-color: var(--dedFamBlack);
  z-index: 0;
}

.roadmapHeadline {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.roadmapContent .roadmapHeadline p {
  font-size: 24px;
  color: var(--dedFamGreen);
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: black;
}

.roadmapContent {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  border-radius: 50px;
  border: var(--border);
  background-color: white;
}

.roadmapContent h4 {
  font-size: 50px;
  margin-bottom: .5rem;
}

.roadmapContent p {
  font-size: 20px;
  font-family: "text";
}

@media (max-width:768px) {
  .roadmapContainer.sectionSpacing {
    padding-left: .5rem;
    padding-right: 1rem;
  }

  .roadmapPath img {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
    z-index: 2;
  }

  .roadmapContent h4 {
    font-size: 30px;
  }
}