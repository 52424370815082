.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.card img {
  aspect-ratio: 1/1;
  border-radius: var(--borderRadius);
  margin-bottom: 2rem;
  object-fit: cover;
  width: 400px;
  height: 500px;
  border: var(--border);
}

.card h4 {
  font-family: "headlines";
  font-size: 22px;
  margin-bottom: .5rem;
  color: white;
  text-transform: uppercase;
}

.card p {
  font-family: "text";
  white-space: pre-wrap;
  color: white;
  font-size: 18px;
}

.cardBlurb {
  width: 270px;
  padding: 1rem;
  border-radius: 15px;
  background-color: var(--dedFamBlack);
}

@media (max-width:768px) {
  .card img {
    margin-bottom: 1rem;
    width: 320px;
    height: 400px;
  }
}